<template>
  <el-dialog v-if="isArrange" title="规则编辑" :visible.sync="isArrange" width="1000px">
    <el-form ref="redformhsRef" style="width:100%" :rules="rules" :model="redform" label-width="166px">
      <div class="content">
        <div class="row-item">
          <el-form-item label="规则名称：" prop="name">
            <el-select class="item-width" size="medium" v-model="redform.name" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.dictLabel" :value="item.dictLabel">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="隔离天数：" prop="days">
            <el-input class="item-width" size="medium" v-model="redform.days" placeholder="请输入" />
          </el-form-item>

          <el-form-item label="每次体温测量次数：" prop="tempTimes">
            <el-input class="item-width" size="medium" v-model="redform.tempTimes" placeholder="请输入" />
          </el-form-item>
        </div>

        <div class="row-item">
          <el-form-item label="开始隔离告知书类型：" prop="name">
            <el-select class="item-width" size="medium" v-model="redform.startBookCode" placeholder="请选择"
              @change="changeStartInformType">
              <el-option v-for="item in startInformType" :key="item.noticeBookCode" :label="item.noticeBookName"
                :value="item.noticeBookCode"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="解除隔离告知书类型：" prop="name">
            <el-select class="item-width" size="medium" v-model="redform.relieveBookCode" placeholder="请选择"
              @change="changeEndInformType">
              <el-option v-for="item in endInformType" :key="item.noticeBookCode" :label="item.noticeBookName"
                :value="item.noticeBookCode"></el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div class="content">
        <div class="row-item">
          <el-form-item v-for="(item, index) in redform.labDays" :label="'核酸检测节点 ' + (index + 1) + '：'" :key="item.key"
            :prop="'labDays.' + index + '.labDaysName'"
            :rules="{ required: true, message: '核酸检测节点不能为空', trigger: 'blur' }">
            第
            <el-input style="width: 118px;" type="number" size="medium" v-model="item.labDaysName" placeholder="请选择" />
            天
            <el-button style="margin-left:10px" size="medium" @click.prevent="removeDomain(index, '核酸')">删除</el-button>
          </el-form-item>
        </div>

        <div class="row-item" v-if="isAntibody">
          <el-form-item v-for="(item, index) in redform.antibody" :label="'抗体检测节点 ' + (index + 1) + '：'" :key="item.key"
            :prop="'antibody.' + index + '.value'" :rules="{ required: true, message: '抗体检测节点不能为空', trigger: 'blur' }">
            第
            <el-input style="width: 118px;" type="number" size="medium" v-model="item.value" placeholder="请选择" /> 天
            <el-button style="margin-left:10px" size="medium" @click.prevent="removeDomain(index, '抗体')">删除</el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="addDomain('核酸')">添加核酸检测点</el-button>
      <el-button size="small" @click="addDomain('抗体')">添加抗体检测点</el-button>
      <el-button size="small" @click="isArrange = false">取消</el-button>
      <el-button size="small" type="primary" @click="addCrowdType">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {
  noticeBookType,
  rqtype,
  rulecrowdsave,
  rulecrowdupdate,
  ruleCrowdInfo
} from '../../../api/RuleManagement/crowdAnalysis';

export default {
  name: "CrowdKindDialog",
  components: {},
  data() {
    return {
      isArrange: false,
      isAntibody: false,
      startInformType: [],
      endInformType: [],
      options: [],
      redform: {
        labDays: [{ labDaysName: "" }],
        antibody: [],
        name: "",
        days: "",
        tempTimes: "",
        startBookCode: "",
        relieveBookCode: ""
      },
      rules: {
        name: [{ required: true, message: " ", trigger: "blur" }],
        days: [{ required: true, message: " ", trigger: "blur" }],
        tempTimes: [{ required: true, message: " ", trigger: "blur" }],
        startBookCode: [{ required: true, message: " ", trigger: "blur" }],
        relieveBookCode: [{ required: true, message: " ", trigger: "blur" }]
      },
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      detailsInfo: null
    }
  },
  props: {},
  computed: {},
  watch: {},
  created() {
  },
  mounted() {
  },
  methods: {
    show(data = null) {
      this.isArrange = true;
      this.detailsInfo = null;
      this.redform = {
        labDays: [{ labDaysName: "" }],
        antibody: [],
        name: "",
        days: "",
        tempTimes: "",
        startBookCode: "",
        relieveBookCode: ""
      };
      this.getNoticeBookType(1, "add");
      this.getNoticeBookType(2, "add");
      this.rqtype()

      if (data) {
        this.detailsInfo = data;
        this.getRuleCrowdInfo()
      }
    },

    // 获取隔离告知书类型
    getNoticeBookType(type, str) {
      let params = {
        orgCode: this.userdoctor.orgCode,
        type: type
      };
      noticeBookType({ params }).then(res => {
        if (type == 1) {
          let data = res.data.data;
          if (!this.detailsInfo && str === "add") {
            this.redform.startBookCode = data[0].noticeBookCode;
            this.redform.startBookName = data[0].noticeBookName;
          }
          this.startInformType = data;
        } else {
          let data = res.data.data;
          if (!this.detailsInfo && str === "add") {
            this.redform.relieveBookCode = data[0].noticeBookCode;
            this.redform.relieveBookName = data[0].noticeBookName;
          }
          this.endInformType = data;
        }
      })
    },

    // 人群type
    async rqtype() {
      let { data } = await rqtype();
      if (data.code == 200) {
        this.options = data.data;
      }
    },

    // 添加检测点
    addDomain(type) {
      if (type === '核酸') {
        this.redform.labDays.push({
          labDaysName: "",
          key: Date.now()
        });
      } else if (type === '抗体') {
        this.isAntibody = true;
        this.redform.antibody.push({
          vaule: "",
          key: Date.now()
        })
      }
    },

    removeDomain(index, type) {
      if (type === '核酸') {
        this.redform.labDays.splice(index, 1);
      } else if (type === '抗体') {
        this.redform.antibody.splice(index, 1);
      }
    },

    // 开始隔离告知书类型
    changeStartInformType() {
      this.startInformType.forEach(item => {
        if (item.noticeBookCode == this.redform.startBookCode) {
          this.redform.startBookName = item.noticeBookName;
        }
      })
    },

    // 解除隔离告知书类型
    changeEndInformType() {
      this.endInformType.forEach(item => {
        if (item.noticeBookCode == this.redform.relieveBookCode) {
          this.redform.relieveBookName = item.noticeBookName;
        }
      })
    },

    // 人群分类详情
    getRuleCrowdInfo() {
      let params = { id: this.detailsInfo.id };
      ruleCrowdInfo({ params }).then(res => {
        let { code, data, msg } = res.data;
        if (code == 200) {
          // 处理 核酸检测节点 数据，用于回显
          let labDays = [], antibody = [];

          if (data.covidLabDays) {
            let labDaysName = data.covidLabDays.split(",");
            labDaysName.forEach(item => {
              labDays.push({ labDaysName: item });
            })
          }

          if (data.antibodyLabDays) {
            this.isAntibody = true;
            let antibodyLabDays = data.antibodyLabDays.split(",");
            antibodyLabDays.forEach(item => {
              antibody.push({ value: item });
            })
          }

          data.labDays = labDays;
          data.antibody = antibody;
          // data.name = Number(data.name);
          this.redform = data;
        } else {
          this.$message.error(msg)
        }
      })
    },

    // 保存
    addCrowdType() {
      let covidLabDays = [], dictSort, antibodyLabDays = [];
      let { labDays, antibody, name } = this.redform;
      labDays.forEach(item => {
        covidLabDays.push(item.labDaysName)
      })

      antibody.forEach(item => {
        antibodyLabDays.push(item.value)
      })

      this.options.forEach(item => {
        if (item.dictLabel == name) dictSort = item.dictSort
      })

      let params = {
        ...this.redform,
        code: dictSort,
        covidLabDays: covidLabDays.toString(),
        antibodyLabDays: antibodyLabDays.toString()
      };

      this.$refs.redformhsRef.validate(async valid => {
        if (valid) {
          let result;
          if (!this.redform.id) {
            result = await rulecrowdsave(params);
          } else {
            result = await rulecrowdupdate(params);
          }
          if (result.data.code == 200) {
            this.isArrange = false;
            this.$message.success("修改成功")
            this.$parent.getListInfo();
          } else {
            this.$message.error(result.data.msg);
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.item-width {
  width: 240px;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .row-item {
    &:first-child {
      margin-left: 30px;
    }

    &:last-child {
      margin-right: 50px;
    }
  }
}
</style>

<style scoped>
.el-dialog__wrapper>>>.el-dialog__body .el-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
